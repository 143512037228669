import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store/index';
import * as routes from '../constants/routes';

const PrivateRoute: React.FC<RouteProps> = (props) => {
  const isLogged = useSelector((state: RootState) => state.user.isLogged);

  if (!isLogged) {
    return (
      <Redirect
        to={{ pathname: routes.AUTH.LOGIN }}
      />
    )
  } else {
    return (
      <Route
        {...props}
      />
    )
  }
}

export default PrivateRoute;
