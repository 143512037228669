import { firebaseAuth, googleProvider } from '../../firebase';

export const SET_USER = 'SET_USER';

export const signInWithGoogle = () => {
  return firebaseAuth.signInWithPopup(googleProvider);
};

export const signIn = (email: string, password: string) => {
  return firebaseAuth.signInWithEmailAndPassword(email, password);
};

export const signOut = () => {
  return firebaseAuth.signOut();
};

export const register = (email: string, password: string) => {
  return firebaseAuth.createUserWithEmailAndPassword(email, password);
}
