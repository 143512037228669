import React from 'react';
import './FullLoader.css';

const PrivateRoute: React.FC = () => {
  return (
    <div className="full-loader"></div>
  )
}

export default PrivateRoute;
