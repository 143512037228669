import { createStore, combineReducers } from '@reduxjs/toolkit';
import userReducer from './user/user.reducer';

const rootReducer = combineReducers({
  user: userReducer,
});

export default createStore(rootReducer);

export type RootState = ReturnType<typeof rootReducer>;
