import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';

import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { peopleOutline, peopleSharp, logOutOutline } from 'ionicons/icons';
import * as routes from '../constants/routes';
import { signOut } from '../store/user/user.actions';
import { useSelector } from 'react-redux';
import { RootState } from '../store/index';
import './Menu.css';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Home',
    url: routes.HOME,
    iosIcon: peopleOutline,
    mdIcon: peopleSharp
  },
];

const Menu: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user.user);

  const logout = () => {
    signOut()
      .then(() => {
        history.replace('/');
      })
  };

  if (location.pathname !== routes.AUTH.LOGIN && location.pathname !== '/' && user) {
    return (
      <IonMenu contentId="main" type="overlay">
        <IonContent>
          <IonList id="inbox-list">
            <IonListHeader>{user.displayName}</IonListHeader>
            <IonNote>{user.email}</IonNote>
            {appPages.map((appPage, index) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                    <IonIcon slot="start" icon={appPage.iosIcon} />
                    <IonLabel>{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
          </IonList>

          <IonList lines="none">
            <IonListHeader>Účet</IonListHeader>
            <IonItem button onClick={logout}>
              <IonIcon slot="start" icon={logOutOutline} />
              Odhlásit se
            </IonItem>
          </IonList>
        </IonContent>
      </IonMenu>
    );
  }

  return null;
};

export default Menu;
