import { IonContent, IonPage, IonCard, IonCardContent, IonList, IonItem, IonLabel, IonInput, IonButton } from '@ionic/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { signInWithGoogle, signIn } from '../../store/user/user.actions';
import * as routes from '../../constants/routes';
import './Login.scss';

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();

  const [error, setError] = useState<string>();

  const emailLogin = () => {
    setError(undefined);
    if (email && password) {
      signIn(email, password)
      .then(() => {
        history.replace('/');
      })
      .catch(err => {
        console.log(err);
        if (err.code === 'auth/invalid-email') {
          setError('Neplatný formát e-mailu.');
        } else if (err.code === 'auth/user-not-found') {
          setError('Uživatel nebyl nalezen.');
        } else if (err.code === 'auth/wrong-password') {
          setError('Neplatné heslo.');
        }
      })
    }
  };

  const history = useHistory();

  const googleLogin = () => {
    setError(undefined);
    signInWithGoogle()
      .then(() => {
        history.replace('/');
      })
      .catch(err => {
        console.log(err);
        if (err.code === 'auth/popup-closed-by-user') {
          setError('Příhlášení přes Google účet bylo uživatelem přerušeno.');
        } else if (err.code === 'auth/user-not-found') {
          setError('Uživatel nebyl nalezen.');
        }
      })
  };

  return (
    <IonPage className="login-page">
      <IonContent className="ion-text-center ion-padding-top">
        <img src="/assets/images/logo_login_page.png" className="logo" alt="SportOnGo" />
        <IonCard className="login-form">
          <IonCardContent>
            <IonList>
              <IonItem>
                <IonLabel>E-mail</IonLabel>
                <IonInput value={email} onIonChange={ e => setEmail(e.detail.value!) }></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel>Heslo</IonLabel>
                <IonInput type="password" value={password} onIonChange={ e => setPassword(e.detail.value!) }></IonInput>
              </IonItem>
            </IonList>
            {error &&
              <div className="error-message">{error}</div>
            }
            <IonButton onClick={emailLogin}>Přihlásit se</IonButton>
            <IonButton fill="clear" href={routes.AUTH.REGISTER}>Registrovat se</IonButton>
          </IonCardContent>
        </IonCard>
        <IonButton color="secondary" onClick={googleLogin}>Přihlásit se přes Google účet</IonButton>
      </IonContent>
    </IonPage>
  );
}

export default Login;
