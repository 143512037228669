import { IonContent, IonPage, IonCard, IonCardContent, IonList, IonItem, IonLabel, IonInput, IonButton } from '@ionic/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { register } from '../../store/user/user.actions';
import * as routes from '../../constants/routes';
import { useDispatch } from 'react-redux';
import { SET_USER } from '../../store/user/user.actions';

const Register: React.FC = () => {
  const [firstname, setFirstname] = useState<string>();
  const [lastname, setLastname] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [repeatPassword, setRepeatPassword] = useState<string>();

  const [error, setError] = useState<string>();

  const history = useHistory();
  const dispatch = useDispatch();

  const onRegister = () => {
    if (password !== repeatPassword) {
      setError('Hesla se neshodují.');
    } else if (email && password && firstname && lastname) {
      register(email, password)
        .then(response => {
          if (response && response.user) {
            const displayName = firstname + ' ' + lastname;

            response.user.updateProfile({ displayName })
              .then(() => {
                dispatch({
                  type: SET_USER,
                  payload: {
                    ...response.user?.toJSON(),
                    displayName
                  }
                })
                history.replace(routes.HOME);
              })
              .catch(err => {
                setError('Omlouváme se, nastala chyba v komunikaci. Zkuste to prosím později.');
              });
          }
        })
        .catch(err => {
          if ('code' in err) {
            if (err.code === 'auth/invalid-email') {
              setError('Neplatný formát e-mailu.');
            } else if (err.code === 'auth/weak-password') {
              setError('Heslo musí mít alespoň 6 znaků.');
            }
          }
        });
    }
  };

  return (
    <IonPage className="login-page">
      <IonContent className="ion-text-center ion-padding-top">
        <img src="/assets/images/logo_login_page.png" className="logo" alt="SportOnGo" />
        <IonCard className="login-form">
          <IonCardContent>
            <IonList>
              <IonItem>
                <IonLabel>Jméno</IonLabel>
                <IonInput value={firstname} onIonChange={ e => setFirstname(e.detail.value!) }></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel>Příjmení</IonLabel>
                <IonInput value={lastname} onIonChange={ e => setLastname(e.detail.value!) }></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel>E-mail</IonLabel>
                <IonInput value={email} onIonChange={ e => setEmail(e.detail.value!) }></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel>Heslo</IonLabel>
                <IonInput type="password" value={password} onIonChange={ e => setPassword(e.detail.value!) }></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel>Opakujte heslo</IonLabel>
                <IonInput type="password" value={repeatPassword} onIonChange={ e => setRepeatPassword(e.detail.value!) }></IonInput>
              </IonItem>
            </IonList>
            {error &&
              <div className="error-message">{error}</div>
            }
            <IonButton onClick={onRegister}>Registrovat se</IonButton>
            <IonButton fill="clear" href={routes.AUTH.LOGIN}>Již mám účet</IonButton>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
}

export default Register;
