import { UserState } from './user';
import { SET_USER } from './user.actions';

const initialState: UserState = {
  isLoading: true,
  isLogged: false,
  user: {}
};

const userReducer = (state: UserState = initialState, action: any) => {
  switch (action.type) {
    case SET_USER:
      return {
        user: action.payload,
        isLogged: !!action.payload,
        isLoading: false,
      }
    default:
      return state;
  }
};

export default userReducer;
