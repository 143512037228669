const firebaseConfig = {
  apiKey: "AIzaSyD8TdGoJ4XUYOJfXlkxqPgJbCXW1BQynqk",
  authDomain: "sportongo-pwa-app.firebaseapp.com",
  databaseURL: "https://sportongo-pwa-app.firebaseio.com",
  projectId: "sportongo-pwa-app",
  storageBucket: "sportongo-pwa-app.appspot.com",
  messagingSenderId: "900729139771",
  appId: "1:900729139771:web:77cfc0241333a39b"
};

export default firebaseConfig;
