import Menu from './components/Menu';
import FullLoader from './components/FullLoader';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from './store/index';
import { SET_USER } from './store/user/user.actions';
import { useDispatch } from 'react-redux';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import * as routes from './constants/routes';
import { firebaseAuth } from './firebase';

import Home from './pages/Home';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import './App.css';

/* Theme variables */
import './theme/variables.css';

const App: React.FC = () => {

  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.user.isLoading);

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(userAuth => {
      if (!!userAuth) {
        console.log(userAuth.toJSON());
        dispatch({
          type: SET_USER,
          payload: userAuth.toJSON()
        });
      } else {
        dispatch({
          type: SET_USER,
          payload: null
        });
      }
    });
  });

  if (isLoading) {
    return (
      <FullLoader />
    )
  } else {
    return (
      <IonApp>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <Menu />
            <IonRouterOutlet id="main">
              <Route path={routes.AUTH.LOGIN} component={Login} exact />
              <Route path={routes.AUTH.REGISTER} component={Register} exact />

              <PrivateRoute path={routes.HOME} component={Home} exact />

              <Redirect from="/" to={routes.HOME} exact />
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>
    );
  }
};

export default App;
